<template>
	<div class="container">
		<!-- 遍历文档 -->
		<div v-for="(temps_field, temps_field_index) in temps_fields" :key="temps_field_index">
			<div class="form"
				v-if="(submitData.temp_ids.includes(temps_field.temp_id) || [-1].includes(temps_field.temp_id)) && isShowTempTitle(temps_field)">

				<!-- 遍历字段 -->
				<Row :gutter="40" type="flex" v-for="(field_group, field_group_index) in temps_field.field_groups"
					:key="field_group_index">
					<Col v-if="field_group.title !== '' && isShowGroupTitle(field_group)" span="24">
					<div class="group-title">{{ field_group.title }}</div>
					</Col>
					<template v-for="(field, field_index) in field_group.fields">
						<Col v-if="(!field.temp_ids || isShowFieldForPageHeader(field.temp_ids)) && !field.isRepeat"
							:span="24 / field_group.columns" :key="field_index">
						<div class="form-item">

							<!-- 字段名 -->
							<Poptip trigger="hover" :title="''"
								:content="field.placeholder ? field.placeholder : field.label" width="300"
								:word-wrap="true">
								<div class="form-label">{{ field.label }}</div>
							</Poptip>

							<!-- 文本输入 -->
							<div class="form-value" v-if="field.type === 'text'">
								<Input class="value"
									:class="[isNotNull(submitData.text_values[field.field_name]) ? 'border-green' : '']"
									v-model="submitData.text_values[field.field_name]"
									:placeholder="field.placeholder ? field.placeholder : field.label"
									@input="inputting(field.field_name, submitData.text_values[field.field_name])" />
								<div class="mind-tips" v-if="field.field_name == mindTipsData.name">
									<div v-for="(it, idx) in mindTipsData.values" :key="idx"
										@click="clickThisTime(field.field_name, it)" class="mind-item">{{ idx + 1 }}.{{ it
										}}
									</div>
								</div>
								<div class="unit" v-if="field.unit">{{ field.unit }}</div>
							</div>

							<!-- 单选框选择 -->
							<div class="form-value" v-else-if="field.type === 'check'">
								<RadioGroup class="value" v-model="submitData.check_values[field.field_name]"
									@on-change="checkChangeCommon($event, field.field_name, field.options)">
									<Radio v-for="(option, option_index) in field.options" :key="option_index"
										:label="option_index" style="margin-right:20px;">{{ option }}</Radio>
								</RadioGroup>
							</div>

							<!-- 文件选择 -->
							<div class="form-value-file" v-else-if="field.type === 'picture'">
								<div class="image"
									v-if="isNotNull(submitData.picture_values[field.field_name]) && isNotNull(submitData.picture_values[field.field_name].src)">
									<viewer>
										<img class="img" :src="submitData.picture_values[field.field_name].src" />
									</viewer>
									<img class="close" src="https://api.ganleizhong.com/static/images/close.png"
										@click="delSelectImg(field.field_name)" />
								</div>
								<div class="image" v-else>
									<img class="img" src="https://api.ganleizhong.com/static/images/nopic.png" />
								</div>
								<Upload :show-upload-list="false"
									:before-upload="(file) => uploadPic(file, field.field_name)" action="#"
									accept="image/png, image/jpeg, image/jpg" style="display: inline-block;">
									<Button class="op-btn" icon="ios-cloud-upload-outline">点击上传</Button>
								</Upload>
							</div>

						</div>
						</Col>
					</template>
				</Row>
			</div>
		</div>
		<!-- 遍历分类 -->
		<div class="form">
			<Row :gutter="40" type="flex" v-for="(field_group, field_group_index) in temps_fields2"
				:key="field_group_index">
				<Col v-if="field_group.title !== '' && isShowGroupTitle(field_group)" span="24">
				<div class="group-title">{{ field_group.title }}</div>
				</Col>
				<template v-for="(field, field_index) in field_group.fields">
					<Col v-if="submitData.temp_ids.includes(field.temp_id) && (!field.temp_ids || isShowFieldForPageHeader(field.temp_ids)) && !field.isRepeat"
						:span="24 / field_group.columns" :key="field_index">
					<div class="form-item">
						<!-- 字段名 -->
						<Poptip trigger="hover" :title="''" :content="field.placeholder ? field.placeholder : field.label"
							width="300" :word-wrap="true">
							<div class="form-label">{{ field.label }}</div>
						</Poptip>
						<!-- 文本输入 -->
						<div class="form-value" v-if="field.type === 'text'">
							<Input class="value"
								:class="[isNotNull(submitData.text_values[field.field_name]) ? 'border-green' : '']"
								v-model="submitData.text_values[field.field_name]"
								:placeholder="field.placeholder ? field.placeholder : field.label"
								@input="inputting(field.field_name, submitData.text_values[field.field_name])" />
							<div class="mind-tips" v-if="field.field_name == mindTipsData.name">
								<div v-for="(it, idx) in mindTipsData.values" :key="idx"
									@click="clickThisTime(field.field_name, it)" class="mind-item">{{ idx + 1 }}.{{ it }}
								</div>
							</div>
							<div class="unit" v-if="field.unit">{{ field.unit }}</div>
						</div>
						<!-- 文本域 -->
						<div class="form-value" v-if="field.type === 'textarea'">
							<textarea class="value" v-model="submitData.text_values[field.field_name]"
								:placeholder="field.placeholder" rows="4" maxlength="250"></textarea>
						</div>
						<!-- 单选框选择 -->
						<div class="form-value" v-else-if="field.type === 'check'">
							<RadioGroup class="value" v-model="submitData.check_values[field.field_name]"
								@on-change="checkChangeCommon($event, field.field_name, field.options)">
								<Radio v-for="(option, option_index) in field.options" :key="option_index"
									:label="option_index" style="margin-right:20px;">{{ option }}</Radio>
							</RadioGroup>
						</div>
						<!-- 文件选择 -->
						<div class="form-value-file" v-else-if="field.type === 'picture'">
							<div class="image"
								v-if="isNotNull(submitData.picture_values[field.field_name]) && isNotNull(submitData.picture_values[field.field_name].src)">
								<viewer>
									<img class="img" :src="submitData.picture_values[field.field_name].src" />
								</viewer>
								<img class="close" src="https://api.ganleizhong.com/static/images/close.png"
									@click="delSelectImg(field.field_name)" />
							</div>
							<div class="image" v-else>
								<img class="img" src="https://api.ganleizhong.com/static/images/nopic.png" />
							</div>
							<Upload :show-upload-list="false" :before-upload="(file) => uploadPic(file, field.field_name)"
								action="#" accept="image/png, image/jpeg, image/jpg" style="display: inline-block;">
								<Button class="op-btn" icon="ios-cloud-upload-outline">点击上传</Button>
							</Upload>
						</div>
					</div>
					</Col>
				</template>
			</Row>
		</div>
		<div class="form__btns">
			<Button class="btn" type="primary" size="large" @click="submit">生成资料</Button>
		</div>
	</div>
</template>

<script>
import {
	createContract,
	mindTips
} from '@/api/contract.js'
import {
	getContractHistory
} from '@/api/user.js'
import {
	imageUrlToFile
} from '@/libs/tools.js'

export default {
	name: 'TempCreate3',
	data() {
		return {
			mindTipsData: {
				name: '',
				values: [],
			},
			temps: [],
			submitData: {
				name: '',
				description: '',
				temp_ids: '', // string
				text_values: {}, // key => value
				check_values: {}, // key(field_name+_+选项下标) => value:(0/1)，默认第一个打勾，填充：0-☐；1-☑
				picture_fields: [], // string
				picture_values: {} // key => { src, file }
			},
			temps_fields: [
				// 页眉
				{
					temp_id: -1,
					field_groups: [{
						temp_ids: [],
						title: '页眉显示',
						isShowTitle: false,
						columns: 4,
						fields: [{
							temp_id: -1,
							type: 'text',
							field_name: 't6',
							label: '施工单位',
							temp_ids: [12, 13, 14, 15, 16, 17],
							isRepeat: false
						},
						/* 22 */
						{
							temp_id: 12,
							type: 'text',
							field_name: 't7',
							label: '监理单位',
							isRepeat: false
						},
						{
							temp_id: 2,
							type: 'text',
							field_name: 't7',
							label: '监理单位',
							isRepeat: false
						},
						{
							temp_id: 4,
							type: 'text',
							field_name: 't7',
							label: '监理单位',
							isRepeat: false
						},
						{
							temp_id: 4,
							type: 'text',
							field_name: 't7',
							label: '监理机构',
							isRepeat: false
						},
						{
							temp_id: 5,
							type: 'text',
							field_name: 't7',
							label: '监理单位',
							isRepeat: false
						},
						{
							temp_id: 26,
							type: 'text',
							field_name: 't7',
							label: '监理单位',
							isRepeat: false
						},
						{
							temp_id: 8,
							type: 'text',
							field_name: 't7',
							label: '监理单位',
							isRepeat: false
						},
						{
							temp_id: 8,
							type: 'text',
							field_name: 't7',
							label: '监理机构',
							isRepeat: false
						},
						{
							temp_id: 9,
							type: 'text',
							field_name: 't7',
							label: '监理单位',
							isRepeat: false
						},
						{
							temp_id: 12,
							type: 'text',
							field_name: 't7',
							label: '监理机构',
							isRepeat: false
						},
						{
							temp_id: 13,
							type: 'text',
							field_name: 't7',
							label: '监理单位',
							isRepeat: false
						},
						{
							temp_id: 19,
							type: 'text',
							field_name: 't7',
							label: '监理单位',
							isRepeat: false
						},
						{
							temp_id: 19,
							type: 'text',
							field_name: 't7',
							label: '监理机构',
							isRepeat: false
						},
						{
							temp_id: 21,
							type: 'text',
							field_name: 't7',
							label: '监理单位',
							isRepeat: false
						},
						{
							temp_id: -1,
							type: 'text',
							field_name: 't8',
							label: '安装单位',
							temp_ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 18, 19, 20, 21, 22, 23,
								24, 25, 26
							],
							isRepeat: false
						},
						{
							temp_id: 10,
							type: 'text',
							field_name: 't12',
							label: '设备名称',
							isRepeat: false
						},
						{
							temp_id: 22,
							type: 'text',
							field_name: 't12',
							label: '设备名称',
							isRepeat: false
						},
						{
							temp_id: 24,
							type: 'text',
							field_name: 't12',
							label: '设备名称',
							isRepeat: false
						},
						{
							temp_id: 25,
							type: 'text',
							field_name: 't12',
							label: '设备名称',
							isRepeat: false
						},

						/* _9_2 */
						{
							temp_id: 17,
							type: 'text',
							field_name: 't33',
							label: '工种',
							isRepeat: false
						},
						]
					}]
				}
			],
			temps_fields2: [{
				title: '工程信息',
				isShowTitle: false,
				columns: 4,
				fields: [
					/* 1 */
					{
						temp_id: 1,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't1',
						label: '名称',
						isRepeat: false
					},
					{
						temp_id: 3,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 4,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 5,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 26,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 7,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 8,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 10,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 11,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 12,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 13,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 14,
						type: 'text',
						field_name: 't1',
						label: '单位(子单位)工程名称',
						isRepeat: false
					},
					{
						temp_id: 15,
						type: 'text',
						field_name: 't1',
						label: '单位(子单位)工程名称',
						isRepeat: false
					},
					{
						temp_id: 16,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 17,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 18,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 19,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 20,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 21,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 22,
						type: 'text',
						field_name: 't1',
						label: '工程名称',
						isRepeat: false
					},
					{
						temp_id: 23,
						type: 'text',
						field_name: 't1',
						label: '工程项目名称',
						isRepeat: false
					},
					{
						temp_id: 25,
						type: 'text',
						field_name: 't1',
						label: '项目名称',
						isRepeat: false
					},
					/* 2 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't2',
						label: '工程地址',
						isRepeat: false
					},
					{
						temp_id: 5,
						type: 'text',
						field_name: 't2',
						label: '工程地点',
						isRepeat: false
					},
					{
						temp_id: 26,
						type: 'text',
						field_name: 't2',
						label: '工程地点',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'text',
						field_name: 't2',
						label: '工程地点',
						isRepeat: false
					},
					{
						temp_id: 13,
						type: 'text',
						field_name: 't2',
						label: '工程地点',
						isRepeat: false
					},
					{
						temp_id: 18,
						type: 'text',
						field_name: 't2',
						label: '工程地址',
						isRepeat: false
					},
					{
						temp_id: 21,
						type: 'text',
						field_name: 't2',
						label: '工程地点',
						isRepeat: false
					},
					{
						temp_id: 22,
						type: 'text',
						field_name: 't2',
						label: '工程地址',
						isRepeat: false
					},
					/* 7 */
					{
						temp_id: 5,
						type: 'text',
						field_name: 't38n',
						label: '施工现场负责人',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'text',
						field_name: 't38n',
						label: '安装(拆卸)负责人',
						isRepeat: false
					},
					{
						temp_id: 13,
						type: 'text',
						field_name: 't38n',
						label: '施工单位现场负责人',
						isRepeat: false
					},
					{
						temp_id: 14,
						type: 'text',
						field_name: 't38n',
						label: '项目负责人',
						isRepeat: false
					},
					{
						temp_id: 16,
						type: 'text',
						field_name: 't38n',
						label: '项目负责人',
						isRepeat: false
					},
					{
						temp_id: 16,
						type: 'text',
						field_name: 't38n',
						label: '项目负责人',
						isRepeat: false
					},
					{
						temp_id: 18,
						type: 'text',
						field_name: 't38n',
						label: '项目负责人',
						isRepeat: false
					},
					{
						temp_id: 18,
						type: 'text',
						field_name: 't38n',
						label: '项目负责人',
						isRepeat: false
					},
					/* 8 */
					{
						temp_id: 5,
						type: 'text',
						field_name: 't38p',
						label: '电话',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'text',
						field_name: 't38p',
						label: '电话',
						isRepeat: false
					},
					{
						temp_id: 13,
						type: 'text',
						field_name: 't38p',
						label: '电话',
						isRepeat: false
					},
					/* 9 */
					{
						temp_id: 5,
						type: 'text',
						field_name: 't39n',
						label: '现场安全负责人',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'text',
						field_name: 't39n',
						label: '施工单位现场安全负责人',
						isRepeat: false
					},
					/* 10 */
					{
						temp_id: 5,
						type: 'text',
						field_name: 't39p',
						label: '电话',
						isRepeat: false
					},
					/* 1_2 */
					{
						temp_id: 14,
						type: 'text',
						field_name: 't35',
						label: '分部(子分部)工程名称',
						isRepeat: false
					},
					/* 11 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't60',
						label: '信用代码（施工单位）',
						isRepeat: false
					},
					/* 12 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't61',
						label: '公司地址（施工单位）',
						isRepeat: false
					},
					/* 13 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't62',
						label: '支付账号（施工单位）',
						isRepeat: false
					},
				]
			},
			{
				title: '监理单位',
				isShowTitle: false,
				columns: 4,
				fields: [{
					temp_id: 13,
					type: 'text',
					field_name: 'w100n',
					label: '监理总监',
					isRepeat: false
				},
				{
					temp_id: 13,
					type: 'text',
					field_name: 'w100p',
					label: '电话',
					isRepeat: false
				},
				/* 23 */
				{
					temp_id: 13,
					type: 'text',
					field_name: 't42n',
					label: '监理单位现场负责人',
					isRepeat: false
				},
				/* 24 */
				{
					temp_id: 13,
					type: 'text',
					field_name: 't42p',
					label: '电话',
					isRepeat: false
				},
				/* 19 */
				{
					temp_id: 14,
					type: 'text',
					field_name: 't36',
					label: '验收部位',
					isRepeat: false
				},
				/* 20 */
				{
					temp_id: 14,
					type: 'text',
					field_name: 't37',
					label: '施工图名称及图号',
					isRepeat: false
				},
				/* 21 */
				{
					temp_id: 15,
					type: 'text',
					field_name: 't32',
					label: '检验批编号',
					isRepeat: false
				},
				]
			},
			{
				title: '出租单位',
				isShowTitle: true,
				columns: 4,
				fields: [
					{
						temp_id: 10,
						type: 'text',
						field_name: 't90',
						label: '信用代码（出租单位）',
						isRepeat: false
					},
					/* 12 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't91',
						label: '公司地址（出租单位）',
						isRepeat: false
					},
					/* 13 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't92',
						label: '收款账号（出租单位）',
						isRepeat: false
					},
				]
			},

			{
				title: '安装单位',
				isShowTitle: true,
				columns: 4,
				fields: [
					/* _1 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't8',
						label: '单位名称',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't8',
						label: '产权单位',
						isRepeat: false
					},
					{
						temp_id: 3,
						type: 'text',
						field_name: 't8',
						label: '专业承包单位',
						isRepeat: false
					},
					{
						temp_id: 5,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 5,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 26,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 26,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 6,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 7,
						type: 'text',
						field_name: 't8',
						label: '专业承包单位',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 14,
						type: 'text',
						field_name: 't8',
						label: '专业承包施工单位',
						isRepeat: false
					},
					{
						temp_id: 17,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 18,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 20,
						type: 'text',
						field_name: 't8',
						label: '专业承包单位',
						isRepeat: false
					},
					{
						temp_id: 21,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 24,
						type: 'text',
						field_name: 't8',
						label: '乙方（安装单位）',
						isRepeat: false
					},
					{
						temp_id: 25,
						type: 'text',
						field_name: 't8',
						label: '安装单位',
						isRepeat: false
					},
					{
						temp_id: 25,
						type: 'text',
						field_name: 't8',
						label: '编制单位',
						isRepeat: false
					},
					/* _2 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't28',
						label: '安全生产许可证号',
						isRepeat: false
					},
					/* _3 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't27',
						label: '企业资质等级及证号',
						isRepeat: false
					},
					/* _4 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't26',
						label: '安装(拆卸)工程合同号',
						isRepeat: false
					},
					/* _5 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't29n',
						label: '企业负责人',
						isRepeat: false
					},
					/* _6 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't29p',
						label: '电话',
						isRepeat: false
					},
					/* _7 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't30n',
						label: '安装(拆卸)负责人',
						isRepeat: false
					},
					{
						temp_id: 5,
						type: 'text',
						field_name: 't30n',
						label: '安装单位现场负责人',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'text',
						field_name: 't30n',
						label: '安装单位现场（副总指挥）负责人',
						isRepeat: false
					},
					{
						temp_id: 14,
						type: 'text',
						field_name: 't30n',
						label: '项目负责人',
						isRepeat: false
					},
					{
						temp_id: 18,
						type: 'text',
						field_name: 't30n',
						label: '项目负责人',
						isRepeat: false
					},
					/* _8 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't30p',
						label: '电话',
						isRepeat: false
					},
					{
						temp_id: 5,
						type: 'text',
						field_name: 't30p',
						label: '电话',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'text',
						field_name: 't30p',
						label: '电话',
						isRepeat: false
					},
					/* _9 */
					{
						temp_id: 4,
						type: 'text',
						field_name: 't12',
						label: '安全专项施工基础方案',
						isRepeat: false
					},
					{
						temp_id: 8,
						type: 'text',
						field_name: 't12',
						label: '安全专项施工基础方案',
						isRepeat: false
					},

					{
						temp_id: 11,
						type: 'text',
						field_name: 't12',
						label: '专项基础施工方案',
						isRepeat: false
					},
					{
						temp_id: 12,
						type: 'text',
						field_name: 't12',
						label: '安全专项施工基础方案',
						isRepeat: false
					},
					{
						temp_id: 16,
						type: 'text',
						field_name: 't12',
						label: '起重机械名称',
						isRepeat: false
					},
					{
						temp_id: 17,
						type: 'text',
						field_name: 't12',
						label: '分部分项工程',
						isRepeat: false
					},
					{
						temp_id: 19,
						type: 'text',
						field_name: 't12',
						label: '安全专项施工基础方案',
						isRepeat: false
					},
					{
						temp_id: 20,
						type: 'text',
						field_name: 't12',
						label: '专业承包工程加节附着方案',
						isRepeat: false
					},
				]
			},


			{
				title: '',
				isShowTitle: false,
				columns: 3,
				fields: [
					/* _20 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_1n',
						label: '1、姓名',
						placeholder: '姓名',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_1j',
						label: '职务或工种',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_1qcn',
						label: '资格证号',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_2n',
						label: '2、姓名',
						placeholder: '姓名',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_2j',
						label: '职务或工种',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_2qcn',
						label: '资格证号',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_3n',
						label: '3、姓名',
						placeholder: '姓名',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_3j',
						label: '职务或工种',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_3qcn',
						label: '资格证号',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_4n',
						label: '4、姓名',
						placeholder: '姓名',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_4j',
						label: '职务或工种',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_4qcn',
						label: '资格证号',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_5n',
						label: '5、姓名',
						placeholder: '姓名',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_5j',
						label: '职务或工种',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_5qcn',
						label: '资格证号',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_6n',
						label: '6、姓名',
						placeholder: '姓名',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_6j',
						label: '职务或工种',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_6qcn',
						label: '资格证号',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_7n',
						label: '7、姓名',
						placeholder: '姓名',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_7j',
						label: '职务或工种',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't31_7qcn',
						label: '资格证号',
						isRepeat: false
					}
				]
			},
			{
				title: '设备信息',
				isShowTitle: false,
				columns: 4,
				fields: [
					/* _33 */
					{
						temp_id: 23,
						type: 'text',
						field_name: 't49',
						label: '设备安装确认内容1',
						placeholder: '确保拟安装塔式起重机的360°回转空间：\n1.安装方案的平面布置图是否符合实际；\n2.平面布置图的图例与尺寸是否符合比例',
						isRepeat: false
					},
					/* _34 */
					{
						temp_id: 23,
						type: 'text',
						field_name: 't50',
						label: '设备安装确认内容2',
						placeholder: '拟安装的塔式起重机任何部位与架空输电线的安全距离是否符合要求；\n不满足要求的，应采取安全防护措施',
						isRepeat: false
					},
					/* _35 */
					{
						temp_id: 23,
						type: 'text',
						field_name: 't51',
						label: '设备安装确认内容3',
						placeholder: '存在把基坑支护桩兼作塔式起重机基础支承桩使用情况的，应提供基坑支护设计单位的书面确认意见',
						isRepeat: false
					},
					/* _36 */
					{
						temp_id: 23,
						type: 'text',
						field_name: 't52',
						label: '设备安装确认内容4',
						placeholder: '在深基坑支护结构边缘安装塔式起重机的，应提供基坑支护设计单位的书面确认意见',
						isRepeat: false
					},
					/* _25 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't9',
						label: '甲方（出租单位）',
						isRepeat: false
					},
					{
						temp_id: 22,
						type: 'text',
						field_name: 't9',
						label: '甲（出租单位）',
						isRepeat: false
					},
					{
						temp_id: 22,
						type: 'text',
						field_name: 't9',
						label: '出租单位',
						isRepeat: false
					},
					{
						temp_id: 22,
						type: 'text',
						field_name: 't56n',
						label: '负责人',
						isRepeat: false
					},
					/* _31 */
					{
						temp_id: 22,
						type: 'text',
						field_name: 't56p',
						label: '负责人电话',
						isRepeat: false
					},
					/* _30 */
					{
						temp_id: 22,
						type: 'text',
						field_name: 't54',
						label: '乙（产权单位）',
						isRepeat: false
					},
					{
						temp_id: 22,
						type: 'text',
						field_name: 't54',
						label: '产权单位',
						isRepeat: false
					},
					{
						temp_id: 22,
						type: 'text',
						field_name: 't55n',
						label: '负责人',
						isRepeat: false
					},
					/* _32 */
					{
						temp_id: 22,
						type: 'text',
						field_name: 't55p',
						label: '负责人电话',
						isRepeat: false
					},
					/* _19_2 */
					{
						temp_id: 1,
						type: 'text',
						field_name: 't22',
						label: '设备产权备案号',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't22',
						label: '设备产权备案号',
						isRepeat: false
					},
					{
						temp_id: 16,
						type: 'text',
						field_name: 't22',
						label: '备案编号',
						isRepeat: false
					},
					{
						temp_id: 22,
						type: 'text',
						field_name: 't22',
						label: '产权编号',
						isRepeat: false
					},
					{
						temp_id: 23,
						type: 'text',
						field_name: 't22',
						label: '塔式起重机备案编号',
						isRepeat: false
					},
					/* _11 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't13',
						label: '出厂编号',
						isRepeat: false
					},
					{
						temp_id: 10,
						type: 'text',
						field_name: 't13',
						label: '设备编号',
						isRepeat: false
					},
					{
						temp_id: 23,
						type: 'text',
						field_name: 't13',
						label: '塔式起重机出厂编号',
						isRepeat: false
					},
					{
						temp_id: 24,
						type: 'text',
						field_name: 't13',
						label: '出厂编号',
						isRepeat: false
					},
					{
						temp_id: 25,
						type: 'text',
						field_name: 't13',
						label: '出厂编号',
						isRepeat: false
					},
					/* _10 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't21',
						label: '制造单位',
						isRepeat: false
					},
					/* _12 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't14',
						label: '出厂日期',
						isRepeat: false
					},
					/* _13 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't15',
						label: '型号',
						isRepeat: false
					},
					{
						temp_id: 16,
						type: 'text',
						field_name: 't15',
						label: '型号规格',
						isRepeat: false
					},
					{
						temp_id: 24,
						type: 'text',
						field_name: 't15',
						label: '设备型号',
						isRepeat: false
					},
					/* _15 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't19',
						label: '最大起重量',
						isRepeat: false
					},
					/* _14 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't20',
						label: '最大起重力矩',
						isRepeat: false
					},
					/* _18 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't18_2',
						label: '跨度',
						isRepeat: false,
						unit: 'm'
					},
					/* _17 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't18_1',
						label: '幅度',
						isRepeat: false
					},
					/* _16 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't16',
						label: '设计最大起升高度',
						isRepeat: false
					},
					/* 13 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't17',
						label: '本工程最大使用高度',
						isRepeat: false
					},
					{
						temp_id: 10,
						type: 'text',
						field_name: 't17',
						label: '高度（米）',
						isRepeat: false
					},
					{
						temp_id: 21,
						type: 'text',
						field_name: 't17',
						label: '最大高度',
						unit: '米',
						isRepeat: false
					},
					{
						temp_id: 24,
						type: 'text',
						field_name: 't17',
						label: '安装高度',
						isRepeat: false
					},
					/* _56 */
					{
						temp_id: 21,
						type: 'text',
						field_name: 't10',
						label: '按设备说明书的技术参数共计划',
						placeholder: ' ',
						unit: '附着',
						isRepeat: false
					},
					/* _26 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't18',
						label: '作业幅度（米）',
						isRepeat: false
					},

					/* _19 */
					{
						temp_id: 2,
						type: 'check',
						field_name: 'c1',
						label: '作业类别',
						options: ['安装', '拆卸'],
						isRepeat: false
					},
					/* 12 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't23',
						label: '工地自编号',
						isRepeat: false
					},
					{
						temp_id: 4,
						type: 'text',
						field_name: 't23',
						label: '编号',
						isRepeat: false
					},
					{
						temp_id: 8,
						type: 'text',
						field_name: 't23',
						label: '编号',
						isRepeat: false
					},
					{
						temp_id: 12,
						type: 'text',
						field_name: 't23',
						label: '编号',
						isRepeat: false
					},
					{
						temp_id: 16,
						type: 'text',
						field_name: 't23',
						label: '工地自编号',
						isRepeat: false
					},
					{
						temp_id: 19,
						type: 'text',
						field_name: 't23',
						label: '编号',
						isRepeat: false
					},
					{
						temp_id: 23,
						type: 'text',
						field_name: 't23',
						label: '塔式起重机在工地的自编号',
						isRepeat: false
					},
					/* 11 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't24',
						label: '设备在工地的安装位置',
						isRepeat: false
					},
					{
						temp_id: 21,
						type: 'text',
						field_name: 't24',
						label: '安装位置分别为',
						placeholder: ' ',
						unit: '米',
						isRepeat: false
					},
					{
						temp_id: 24,
						type: 'text',
						field_name: 't24',
						label: '安装位置',
						isRepeat: false
					},
					/* 14 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't25',
						label: '计划安装(拆卸)日期',
						isRepeat: false
					},
					/* _29 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't47',
						label: '进出场费（元）',
						isRepeat: false
					},
					{
						temp_id: 24,
						type: 'text',
						field_name: 't47',
						label: '维保期限',
						isRepeat: false
					},
					/* _27 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't46',
						label: '出租期限（月）',
						isRepeat: false
					},
					/* _28 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't45',
						label: '月租价',
						isRepeat: false
					},
					/* _37 */
					{
						temp_id: 24,
						type: 'text',
						field_name: 't53',
						label: '保养人工费用',
						isRepeat: false
					},

					/* _21 */
					{
						temp_id: 5,
						type: 'text',
						field_name: 't44n',
						label: '汽车吊司索',
						isRepeat: false
					},
					/* _22 */
					{
						temp_id: 5,
						type: 'text',
						field_name: 't44p',
						label: '电话',
						isRepeat: false
					},
					/* _23 */
					{
						temp_id: 5,
						type: 'text',
						field_name: 't43n',
						label: '汽车吊责任人',
						isRepeat: false
					},
					/* _24 */
					{
						temp_id: 5,
						type: 'text',
						field_name: 't43p',
						label: '电话',
						isRepeat: false
					},
					/* _25 */
					{
						temp_id: 10,
						type: 'text',
						field_name: 't48',
						label: '设备估计值',
						isRepeat: false
					},


				]
			},
			{
				title: '图片编号',
				isShowTitle: false,
				columns: 4,
				fields: [
					/* _38 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p6',
						label: '安装单位资质',
						isRepeat: false
					},
					/* _39 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p7',
						label: '安全生产许可证',
						isRepeat: false
					},
					/* _40 */
					{
						temp_id: 26,
						type: 'picture',
						field_name: 'p24',
						label: '安装公司人员证件图',
						isRepeat: false
					},
					/* _41 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p8',
						label: '人员保险单',
						isRepeat: false
					},
					/* _42 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p2',
						label: '设备产权证明',
						isRepeat: false
					},
					{
						temp_id: 21,
						type: 'picture',
						field_name: 'p2',
						label: '设备产权信息图',
						isRepeat: false
					},
					{
						temp_id: 26,
						type: 'picture',
						field_name: 'p2',
						label: '设备产权信息图',
						isRepeat: false
					},
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'w101',
						label: '施工许可证图',
						isRepeat: false
					},
					{
						temp_id: 5.2,
						type: 'picture',
						field_name: 'w101',
						label: '施工许可证图',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'picture',
						field_name: 'w101',
						label: '施工许可证图',
						isRepeat: false
					},
					{
						temp_id: 13,
						type: 'picture',
						field_name: 'w101',
						label: '施工许可证图',
						isRepeat: false
					},
					{
						temp_id: 21,
						type: 'picture',
						field_name: 'w101',
						label: '施工许可证图',
						isRepeat: false
					},
					/* _43 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p4',
						label: '设备现况图',
						isRepeat: false
					},
					/* _44 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p3',
						label: '设备铭牌图',
						isRepeat: false
					},
					/* _55 */
					{
						temp_id: 13,
						type: 'picture',
						field_name: 'p21',
						label: '设备基础要求参数',
						isRepeat: false
					},
					/* 15 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p1',
						label: '施工设备布置图',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'picture',
						field_name: 'p1',
						label: '施工设备布置图',
						isRepeat: false
					},
					/* 16 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p5',
						label: '设备进场证明',
						isRepeat: false
					},
					/* 17 */
					{
						temp_id: 9,
						type: 'picture',
						field_name: 'p18',
						label: '施工现场应急救援路线图',
						isRepeat: false
					},

					/* _45 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p9',
						label: '基础套架',
						isRepeat: false
					},
					/* _46 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p10',
						label: '回转、操作室',
						isRepeat: false
					},
					/* _47 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p11',
						label: '过渡节、塔帽',
						isRepeat: false
					},
					/* _48 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p12',
						label: '平衡臂、配重',
						isRepeat: false
					},
					/* _49 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p13',
						label: '起重臂、配重',
						isRepeat: false
					},
					/* _50 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p14',
						label: '整机',
						isRepeat: false
					},

					/* 18 */
					{
						temp_id: 9,
						type: 'picture',
						field_name: 'p19',
						label: '辅助设备通讯图',
						isRepeat: false
					},
					/* _51 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p15',
						label: '汽车吊司机操作证',
						isRepeat: false
					},
					/* _52 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p16',
						label: '汽车吊行驶证',
						isRepeat: false
					},
					/* _57 */
					{
						temp_id: 21,
						type: 'picture',
						field_name: 'p23',
						label: '安装公司现场组织执行人员列表',
						isRepeat: false
					},
					/* _53 */
					{
						temp_id: 5,
						type: 'picture',
						field_name: 'p17',
						label: '汽车吊车辆保险',
						isRepeat: false
					},
					/* _54 */
					{
						temp_id: 13,
						type: 'picture',
						field_name: 'p20',
						label: '设备基础信息图',
						isRepeat: false
					},
				]
			},
			{
				title: '其他',
				isShowTitle: false,
				columns: 4,
				fields: [

					/* _58 */
					{
						temp_id: 9,
						type: 'text',
						field_name: 't11n',
						label: '住建局项目主监员',
						isRepeat: false
					},
					/* _59 */
					{
						temp_id: 9,
						type: 'text',
						field_name: 't11p',
						label: '电话',
						isRepeat: false
					},


					/* 6 */
					{
						temp_id: 2,
						type: 'text',
						field_name: 't6',
						label: '总承包单位',
						isRepeat: false
					},
					{
						temp_id: 2,
						type: 'text',
						field_name: 't6',
						label: '使用单位',
						isRepeat: false
					},
					{
						temp_id: 3,
						type: 'text',
						field_name: 't6',
						label: '总承包单位',
						isRepeat: false
					},
					{
						temp_id: 4,
						type: 'text',
						field_name: 't6',
						label: '总承包单位',
						isRepeat: false
					},
					{
						temp_id: 5,
						type: 'text',
						field_name: 't6',
						label: '施工单位',
						isRepeat: false
					},
					{
						temp_id: 26,
						type: 'text',
						field_name: 't6',
						label: '施工单位',
						isRepeat: false
					},
					{
						temp_id: 6,
						type: 'text',
						field_name: 't6',
						label: '施工单位',
						isRepeat: false
					},
					{
						temp_id: 7,
						type: 'text',
						field_name: 't6',
						label: '总承包单位',
						isRepeat: false
					},
					{
						temp_id: 8,
						type: 'text',
						field_name: 't6',
						label: '总承包单位',
						isRepeat: false
					},
					{
						temp_id: 9,
						type: 'text',
						field_name: 't6',
						label: '施工单位',
						isRepeat: false
					},
					{
						temp_id: 10,
						type: 'text',
						field_name: 't6',
						label: '甲方（施工单位）',
						isRepeat: false
					},
					{
						temp_id: 11,
						type: 'text',
						field_name: 't6',
						label: '总承包单位',
						isRepeat: false
					},
					{
						temp_id: 12,
						type: 'text',
						field_name: 't6',
						label: '总承包单位',
						isRepeat: false
					},
					{
						temp_id: 13,
						type: 'text',
						field_name: 't6',
						label: '施工单位',
						isRepeat: false
					},
					{
						temp_id: 14,
						type: 'text',
						field_name: 't6',
						label: '总承包施工单位',
						isRepeat: false
					},
					{
						temp_id: 15,
						type: 'text',
						field_name: 't6',
						label: '施工单位',
						isRepeat: false
					},
					{
						temp_id: 16,
						type: 'text',
						field_name: 't6',
						label: '总承包单位',
						isRepeat: false
					},
					{
						temp_id: 16,
						type: 'text',
						field_name: 't6',
						label: '基础施工单位',
						isRepeat: false
					},
					{
						temp_id: 17,
						type: 'text',
						field_name: 't6',
						label: '施工单位',
						isRepeat: false
					},
					{
						temp_id: 18,
						type: 'text',
						field_name: 't6',
						label: '施工单位',
						isRepeat: false
					},
					{
						temp_id: 18,
						type: 'text',
						field_name: 't6',
						label: '使用单位',
						isRepeat: false
					},
					{
						temp_id: 19,
						type: 'text',
						field_name: 't6',
						label: '总承包单位',
						isRepeat: false
					},
					{
						temp_id: 20,
						type: 'text',
						field_name: 't6',
						label: '总承包单位',
						isRepeat: false
					},
					{
						temp_id: 21,
						type: 'text',
						field_name: 't6',
						label: '施工单位',
						isRepeat: false
					},
					{
						temp_id: 24,
						type: 'text',
						field_name: 't6',
						label: '甲方（施工单位）',
						isRepeat: false
					},
				]
			},
			{
				title: '合作协议',
				isShowTitle: false,
				columns: 4,
				fields: [{
					temp_id: 27,
					type: 'text',
					field_name: 'w102',
					label: '甲方单位',
					isRepeat: false
				},
				// {
				// 	temp_id: 27,
				// 	type: 'text',
				// 	field_name: 'w103n',
				// 	label: '甲方负责人',
				// 	isRepeat: false
				// },
				{
					temp_id: 27,
					type: 'text',
					field_name: 'w103p',
					label: '电话号码',
					isRepeat: false
				},

				{
					temp_id: 27,
					type: 'text',
					field_name: 'w104',
					label: '乙方单位',
					isRepeat: false
				},
				// {
				// 	temp_id: 27,
				// 	type: 'text',
				// 	field_name: 'w104n',
				// 	label: '乙方负责人',
				// 	isRepeat: false
				// },
				{
					temp_id: 27,
					type: 'text',
					field_name: 'w104p',
					label: '电话号码',
					isRepeat: false
				},
				]
			},
			{
				title: '',
				isShowTitle: false,
				columns: 1,
				fields: [{
					temp_id: 27,
					type: 'textarea',
					field_name: 'w105',
					label: '协商内容',
					isRepeat: false,
					placeholder: '协议开头已补充“经甲乙双方共同协商”'
				},]
			}
			],
			historyData: {},
		}
	},
	computed: {
		getTempName() {
			return function (temp_id) {
				const temp = this.temps.find(item => item.id === temp_id)
				if (temp) {
					return parseFloat(temp.order) + '、' + temp.name
				} else {
					return ''
				}
			}
		},
		// 是否显示字段，页眉、页脚专用
		isShowFieldForPageHeader() {
			return function (temp_ids) {
				let flag = false
				for (let i = 0; i < temp_ids.length; i++) {
					if (this.temps.find(item => item.id === temp_ids[i])) {
						flag = true
						break
					}
				}
				return flag
			}
		},
		// 是否显示文档标题
		isShowTempTitle() {
			return function (temps_field) {
				let flag = false
				for (let i = 0; i < temps_field.field_groups.length; i++) {
					if (temps_field.field_groups[i].fields.find(item => !item.isRepeat)) {
						flag = true
						break
					}
				}
				return flag
			}
		},
		// 是否显示分组标题
		isShowGroupTitle() {
			return function (field_group) {
				let flag = false
				if (field_group.fields.find(item => !item.isRepeat)) {
					flag = true
				}
				return flag
			}
		},
		checkCompleted() {
			return function (temps_field_index) {
				let flag = true
				const temps_field = this.temps_fields[temps_field_index]
				for (let i = 0; i < temps_field.field_groups.length; i++) {
					const field_group = temps_field.field_groups[i]
					for (let j = 0; j < field_group.fields.length; j++) {
						const field = field_group.fields[j]
						switch (field.type) {
							case 'text':
								flag = this.submitData.text_values[field.field_name] !== ''
								break
							case 'check':
								break
							case 'picture':
								flag = this.submitData.picture_values[field.field_name].file !== null
						}
						if (!flag) break
					}
					if (!flag) break
				}
				return flag
			}
		}
	},
	watch: {},
	async mounted() {
		setTimeout(() => {
			this.$Spin.show();
		}, 500)
		if (this.$route.params == null || Object.keys(this.$route.params).length === 0) {
			this.$router.push({
				name: 'template'
			})
		} else {
			// 把标题等信息存储到submitData中
			// this.$route.params
			this.submitData.name = this.$route.params.name
			this.submitData.description = this.$route.params.description
			// this.submitData.temp_ids = JSON.parse(this.$route.params.temp_ids);
			this.submitData.temp_ids = this.$route.params.temp_ids;
			this.temps = this.$route.params.temps

			var res = await getContractHistory()
			this.historyData = res.content

			// 设置值
			for (var x in this.temps_fields) {
				var temps_field = this.temps_fields[x]
				for (var y in temps_field.field_groups) {
					var field_group = temps_field.field_groups[y]
					for (var j in field_group.fields) {
						var field = field_group.fields[j]
						switch (field.type) {
							case 'text':
								if (!this.submitData.text_values.hasOwnProperty(field.field_name)) {
									this.$set(this.submitData.text_values, field
										.field_name,
										this.historyData[field
											.field_name])
								} else {
									this.temps_fields[x].field_groups[y].fields[j].isRepeat = true
								}
								break
							case 'check':
								if (field.options && field.options.length > 0) {
									if (!this.submitData.check_values.hasOwnProperty(field
										.field_name)) {
										this.$set(this.submitData.check_values, field
											.field_name, 0)
										for (let optionIndex = 0; optionIndex < field
											.options
											.length; optionIndex++) {
											if (optionIndex === 0) {
												this.$set(this.submitData.check_values,
													field
														.field_name + '_' +
													optionIndex, 1)
											} else {
												this.$set(this.submitData.check_values,
													field
														.field_name + '_' +
													optionIndex, 0)
											}
										}
									} else {
										this.temps_fields[x].field_groups[y].fields[j].isRepeat = true
									}
								}
								break
							case 'picture':
								if (!this.submitData.picture_values.hasOwnProperty(field
									.field_name)) {
									this.submitData.picture_fields.push(field
										.field_name)
									if (this.historyData[field.field_name]) {
										var tempfile = await imageUrlToFile(
											'https://api.ganleizhong.com/' + this
												.historyData[field.field_name])
										this.$set(this.submitData.picture_values, field
											.field_name, {
											src: 'https://api.ganleizhong.com/' +
												this
													.historyData[field.field_name],
											file: tempfile
										})
									} else {
										this.$set(this.submitData.picture_values, field
											.field_name, {
											src: null,
											file: null
										})
									}
								} else {
									this.temps_fields[x].field_groups[y].fields[j].isRepeat = true
								}
								break
						}
					}
				}
			}
			// 设置值2
			for (var x in this.temps_fields2) {
				var field_group = this.temps_fields2[x]
				for (var y in field_group.fields) {
					var field = field_group.fields[y]
					switch (field.type) {
						case 'text':
							if (!this.submitData.text_values.hasOwnProperty(field.field_name)) {
								this.$set(this.submitData.text_values, field.field_name,
									this.historyData[field
										.field_name])
							} else {
								this.temps_fields2[x].fields[y].isRepeat = true
							}
							break
						case 'check':
							if (field.options && field.options.length > 0) {
								if (!this.submitData.check_values.hasOwnProperty(field.field_name)) {
									this.$set(this.submitData.check_values, field
										.field_name,
										0)
									for (let optionIndex = 0; optionIndex < field.options
										.length; optionIndex++) {
										if (optionIndex === 0) {
											this.$set(this.submitData.check_values, field
												.field_name +
												'_' + optionIndex, 1)
										} else {
											this.$set(this.submitData.check_values, field
												.field_name +
												'_' + optionIndex, 0)
										}
									}
								} else {
									this.temps_fields2[x].fields[y].isRepeat = true
								}
							}
							break
						case 'picture':
							if (!this.submitData.picture_values.hasOwnProperty(field.field_name)) {
								this.submitData.picture_fields.push(field.field_name)
								if (this.historyData[field.field_name]) {
									var tempfile = await imageUrlToFile(
										'https://api.ganleizhong.com/' + this.historyData[
										field
											.field_name])
									this.$set(this.submitData.picture_values, field
										.field_name, {
										src: 'https://api.ganleizhong.com/' + this
											.historyData[field.field_name],
										file: tempfile
									})
								} else {
									this.$set(this.submitData.picture_values, field
										.field_name, {
										src: null,
										file: null
									})
								}
							} else {
								this.temps_fields2[x].fields[y].isRepeat = true
							}
							break
					}
				}
			}

		}
		setTimeout(() => {
			this.$Spin.hide()
		}, 1500)
	},
	methods: {
		// 单选框选择改变事件 - 通用方法
		checkChangeCommon(e, field_name, options) {
			if (options && options.length > 0) {
				for (let optionIndex = 0; optionIndex < options.length; optionIndex++) {
					if (optionIndex === e) {
						this.$set(this.submitData.check_values, field_name + '_' + optionIndex, 1)
					} else {
						this.$set(this.submitData.check_values, field_name + '_' + optionIndex, 0)
					}
				}
			}
		},
		// 图片选择事件
		uploadPic(file, field_name) {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onloadend = e => {
				const item = this.submitData.picture_values[field_name]
				item.src = reader.result
				item.file = file
			}
			return false
		},
		// 提交
		async submit() {
			this.$Modal.confirm({
				title: '确定生成资料？',
				content: '请确保信息已填写完整，一旦生成无法再进行修改！',
				onOk: async () => {
					this.$Spin.show()
					try {
						// 图片字段转换
						const picture_fields = JSON.stringify(this.submitData
							.picture_fields.map(
								item => {
									return item
								}))
						// 图片
						const fileDes = []
						this.submitData.picture_fields.forEach(field_name => {
							const file = this.submitData.picture_values[field_name]
								.file
							if (file !== null) {
								fileDes.push({
									name: field_name,
									file: file
								})
							}
						})
						// 其他信息
						const submitData = {
							name: this.submitData.name,
							description: this.submitData.description,
							temp_ids: this.submitData.temp_ids, // string[]
							// 需要根据temps_fields先生成value数据
							text_values: JSON.stringify(this.submitData
								.text_values), // key => value[]
							check_values: JSON.stringify(this.submitData
								.check_values
							), // key(field_name+_+选项下标) => value:(0/1)[]，默认第一个打勾，填充：0-☐；1-☑
							picture_fields // string[]
						}
						await createContract(fileDes, submitData)
						this.$Message.success('生成资料成功')
						this.$router.push({
							path: '/user',
							query: {
								menuActive: '1'
							}
						})
					} catch (err) {
						this.$Message.error(err.message)
					}
					this.$Spin.hide()
				}
			})
		},


		//input输入事件
		async inputting(name, value) {
			if (value == '') {
				this.mindTipsData = {
					name: '',
					values: []
				}
				return
			}
			var res = await mindTips({
				name: name,
				value: value
			})
			this.mindTipsData = {
				name: name,
				values: res.content
			}
		},

		clickThisTime(name, value) {
			this.submitData.text_values[name] = value;
			this.mindTipsData = {
				name: '',
				values: []
			}
		},

		delSelectImg(name) {
			this.submitData.picture_values[name] = {
				src: null,
				file: null
			}
		},

		isNotNull(val) {
			console.log(val)
			if (val !== '' && val !== null && val !== undefined) {
				return true;
			} else {
				return false;
			}
		}
	}
}
</script>

<style lang="less" scoped>
.container {
	padding: 20px 40px;
	background-color: #fff;
}

.form {
	border-top: 1px dashed #aaa;
	border-bottom: 1px dashed #aaa;
	margin-bottom: 1px;
	padding-bottom: 20px;

	&:first-child {
		border-top: 0;
	}

	&:last-child {
		border-bottom: 0;
	}

	.title-box {

		color: #933;

		&.completed {
			color: #393;
		}

		.title {
			padding: 20px 0;
			font-size: 20px;
			font-weight: 700;
		}
	}

	.group-title {
		position: relative;
		padding: 3px 0;
		padding-left: 12px;
		margin: 7px 0;
		font-size: 18px;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 6px;
			background-color: #369;
			border-top-right-radius: 5px;
			border-bottom-left-radius: 5px;
		}
	}

	.form-item {
		padding-bottom: 10px;
		font-size: 18px;

		.form-label {
			padding-top: 10px;
			padding-bottom: 2px;

			overflow: hidden;
			word-break: break-all;
			/* break-all(允许在单词内换行。) */
			text-overflow: ellipsis;
			/* 超出部分省略号 */
			display: -webkit-box;
			/** 对象作为伸缩盒子模型显示 **/
			-webkit-box-orient: vertical;
			/** 设置或检索伸缩盒对象的子元素的排列方式 **/
			-webkit-line-clamp: 1;
			/** 显示的行数 **/
		}

		.form-value {
			display: flex;
			align-items: center;

			.value {
				flex: 1;

				/deep/.ivu-input {
					border-color: #d33 !important;
					border-width: 1px !important;
				}

				&.border-green {
					/deep/.ivu-input {
						border-color: #393 !important;
						border-width: 2px !important;
					}
				}
			}

			textarea {
				border-color: #d33 !important;
				border-width: 1px !important;
				border-radius: 4px;
			}

			.mind-tips {
				width: 195px;
				background-color: #4183C4;
				position: absolute;
				border: 1px solid lightgray;
				top: 71px;
				z-index: 9999;

				.mind-item {
					font-size: 15px;
					font-weight: bold;
					padding: 5px;
					color: #ffffff;
					letter-spacing: 1px;
				}
			}

			.unit {
				padding-left: 3px;
			}
		}

		// form-value
		.form-value-file {
			// text-align: center;

			// .image {
			//   width: 100%;
			//   height: 150px;
			//   border: 1px solid #e0e0e0;
			//   border-radius: 5px;
			// }
			.image {
				position: relative;
				width: 100%;
				height: 0px;
				padding-top: 100%; //padding-bottom都可以

				border: 1px solid #e0e0e0;
				border-radius: 5px;
				margin-bottom: 10px;

				.img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}

				.close {
					position: absolute;
					width: 30px;
					height: 30px;
					right: 5px;
					top: 5px;
					opacity: 0.9;
				}
			}

			/deep/.ivu-upload {
				width: 100%;

				.op-btn {
					width: 100%;
				}
			}
		}
	}

	// form-item
}

// form

.form__btns {
	padding: 30px 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	.btn {}
}

// form__btns
</style>
